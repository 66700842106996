import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import { client } from "../../api/client"

const apiURL = process.env.REACT_APP_GENESYS_API_URL
const usersApiURL = process.env.REACT_APP_STATIC_LISTS_API_URL

const initialState = {
    users: [],
    qs: [],
    status: 'idle',
    error: null,
}

export const fetchUsers = createAsyncThunk('filter/fetchUsers', async () => {
    if (usersApiURL) {
        const response = await client.get(usersApiURL, '/users')
        console.log('fetchUsers', response.data)
        return response.data
    } else {
        const response = await client.get(apiURL, '/api/v1/users')
        console.log('fetchUsers', response.data)
        return response.data
    }
})

export const fetchQs = createAsyncThunk('filter/fetchQs', async () => {
    if (process.env.REACT_APP_DISABLE_QUEUES === 'true') {
        console.log('fetchQs disabled by environment variable')
        return [] // return empty array when disabled
    }
    const response = await client.get(apiURL, '/api/v1/queues')
    console.log('fetchQs', response.data)
    return response.data
})

const filterSlice = createSlice({
    name: 'filter',
    initialState,
    extraReducers(builder) {
        builder
        .addCase(fetchUsers.pending, (state, action) => {
            state.status = 'loading'
          })
          .addCase(fetchUsers.fulfilled, (state, action) => {
            state.status = 'succeeded'
            // Add any fetched users to the array
            state.users = action.payload
          })
          .addCase(fetchUsers.rejected, (state, action) => {
            state.status = 'failed'
            state.error = action.error.message
          })
          .addCase(fetchQs.pending, (state, action) => {
            state.status = 'loading'
          })
          .addCase(fetchQs.fulfilled, (state, action) => {
            state.status = 'succeeded'
            // Add any fetched qs to the array
            state.qs = action.payload
          })
          .addCase(fetchQs.rejected, (state, action) => {
            state.status = 'failed'
            state.error = action.error.message
          })
    }
})

export default filterSlice.reducer

export const selectAllUsers = (state) => state.filter.users
export const selectAllQs = (state) => state.filter.qs

export const selectUserById = (state, userId) =>
  state.filter.users.find((user) => user.id === userId)
